
html, body {
  overflow: hidden;
}

.fullheight {
  height: 100vh;
  height: 100dvh;
}

.truncate {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-front {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  direction: rtl;
}

.panel-medium {
  width: 760px;
  max-width: 100vw;
  min-width: 40vw;
  height: 100vh;
  background: var(--background-default-content, #ECEFF1);
}

body:not(.mobile) .panel-medium .panel-content {
  padding-top: 24px;
  padding-left: 32px;
  padding-right: 32px;
}

body.mobile .panel-medium .panel-content {
  padding-top: 24px;
  padding-left: 12px;
  padding-right: 12px;
}

.panel-medium .panel-footer {
  background: var(--background-default, #FFF);
  box-shadow: 0 0 0.1em #0004, 0 0 0.5em #0001;
  position: relative;
}

.nav-root:hover .legacy {
  opacity: 0.4;
}

.hover-link {
  text-decoration: none;
}

.hover-link:hover {
  text-decoration: underline;
}

.MuiBox-root.outline {
  border: 1px solid var(--text-secondary, rgba(0, 0, 0, 0.23));
  border-radius: 8px;
  padding: 16px 20px;
}

.MuiBox-root.animbuttons {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.MuiBox-root.animbuttons.active {
  height: 3.3rem;
  opacity: 1;
}

.MuiFormControl-root.modified label {
  font-weight: 700;
  color: var(--primary-dark, #1565C0);
}

.MuiFormControl-root.modified fieldset {
  border: 2.5px solid var(--primary-dark, #1565C0) !important;
}

.MuiFormControlLabel-root.modified .MuiFormControlLabel-label {
  font-weight: 700;
}




.MuiTextField-root.expandable-search .MuiInputAdornment-root {
  pointer-events: none;
}

.searchbox .MuiTextField-root .MuiOutlinedInput-root {
  background: color-mix(in srgb, var(--background-default, #fff) 50%, var(--background-default-content, #ECEFF1) 50%);
  transition: width 0.2s ease-in-out;
}

body.mobile .searchbox { 
  position: relative;
  height: 40px;
}

body.mobile .searchbox .MuiTextField-root .MuiOutlinedInput-root {
  position: absolute;
  top: 0;
  right: 0;
}

body.mobile .MuiTextField-root.expandable-search .MuiOutlinedInput-root {
  width: 64px;
}

body.mobile .MuiTextField-root.expandable-search .MuiOutlinedInput-root:focus-within {
  width: 206px;
}

body.mobile .MuiTextField-root.expanded-search .MuiOutlinedInput-root {
  width: 206px;
}

.fill-height .MuiFormControl-root.MuiTextField-root {
  height: calc(100% - 1.5rem);
}

.fill-height .MuiFormControl-root.MuiTextField-root .MuiInputBase-root{
  height: 100%;
}

.fill-height .MuiFormControl-root.MuiTextField-root textarea {
  height: 100% !important;
}

::-webkit-scrollbar {
  width: 11px;
  height: 11px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

:hover::-webkit-scrollbar-thumb {
  background: #8889;
}

::-webkit-scrollbar-thumb {
  background: #8888;
  border-radius: 10px;
  border: 3px solid var(--background-default-content, #ECEFF1);
}

.nav-root::-webkit-scrollbar-thumb {
  border: 3px solid var(--background-default, #FFF) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #888a;
}

::-webkit-scrollbar-button {
  display: none;
}