
body.admin-hide .admin-hide {
  display: none;
}

body.admin-hide .admin-hide-opacity {
  opacity: 0;
}

body.admin-hide .admin-hide-opacity:hover {
  opacity: 1;
}

.admin-panel {
  width: 0;
  transition: width 0.3s ease-in-out;
}

.admin-panel.open, .admin-panel .admin-panel-content {
  width: 560px;
  max-width: 50vw;
}

.admin-panel .MuiTab-textColorPrimary {
  color: #aaa !important;
  flex: 1;
}

.admin-panel .MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
}

.admin-panel .MuiTabs-indicator {
  background: #ddd !important;
}

.admin-panel .MuiTableCell-root {
  color: #fff;
}
